import { createBrowserRouter, Link } from "react-router-dom";

import { Header } from "./layout/Header";

import { PrivateRoute } from "./components/PrivateRoutes";
import { LoginPage } from "./pages/Login";
import { RegisterPage } from "./pages/Register";

import { InternalPagesBody } from "./layout/InternalPages/Body";
import { DashboardPage } from "./pages/Dashboard";
import { TransactionsPage } from "./pages/Transactions";
import { AccountsPage } from "./pages/Accounts";
import { CategoriesPage } from "./pages/Categories";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <PrivateRoute>
                <Header />
                <InternalPagesBody>
                    <DashboardPage />
                </InternalPagesBody>
            </PrivateRoute>
        ),
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/register",
        element: <RegisterPage />,
    },
    {
        path: "/transactions",
        element: (
            <PrivateRoute>
                <Header />
                <InternalPagesBody>
                    <TransactionsPage />
                </InternalPagesBody>
            </PrivateRoute>
        ),
    },
    {
        path: "/accounts",
        element: (
            <PrivateRoute>
                <Header />
                <InternalPagesBody>
                    <AccountsPage />
                </InternalPagesBody>
            </PrivateRoute>
        ),
    },
    {
        path: "/categories",
        element: (
            <PrivateRoute>
                <Header />
                <InternalPagesBody>
                    <CategoriesPage />
                </InternalPagesBody>
            </PrivateRoute>
        ),
    },
]);
